import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import ReactPlayer from 'react-player';
import { isMobile } from 'react-device-detect';
import titleImg from '../../assets/landing/media/main.png';
import prevArrowImg from '../../assets/landing/media/arrow_back_1.svg';
import nextArrowImg from '../../assets/landing/media/arrow_front_1.svg';
import titleImg_En from '../../assets/landingEn/media/main_En.png';
import mobileTitle from '../../assets/landing/mobile/video/title.png';
import mobileTitle_En from '../../assets/landingEn/mobile/video/title.png';

SwiperCore.use([Pagination, Navigation]);
const ServiceYoutubeContent = () => {
  const { i18n } = useTranslation('service');

  // const YoutubeDataList = [];
  // const setYoutubeSlides = async () => {
  //   YoutubeDataList.push(
  //     <SwiperSlide key="slide0" tag="li">
  //       <ReactPlayer
  //         url="https://www.youtube.com/watch?v=qhZZIrSiWFI"
  //         width="49.8rem"
  //         height="27.98rem"
  //       />
  //     </SwiperSlide>,
  //     <SwiperSlide key="slide1" tag="li">
  //       <ReactPlayer
  //         url="https://www.youtube.com/watch?v=uX6ICy4pilU"
  //         width="49.8rem"
  //         height="27.98rem"
  //       />
  //     </SwiperSlide>,
  //   );
  // };
  // setYoutubeSlides();
  if (i18n.language === 'en') {
    return null;
  }

  return (
    <>
      <ServiceYoutubeContentWrapper>
        <TitleImg
          src={
            i18n.language === 'en'
              ? isMobile
                ? mobileTitle_En
                : titleImg_En
              : isMobile
              ? titleImg
              : titleImg
          }
          isen={i18n.language}
          data-aos="fade-up"
          data-aos-offset="40"
          data-aos-duration="750"
          data-aos-easing="ease"
        />
        <SwiperFrame
          id="waplYoutube"
          navigation
          pagination={{ clickable: true }}
          centeredSlides
          loop={!isMobile}
          slidesPerView={1}
        >
          <SwiperSlide key="slide0" tag="li">
            <ReactPlayer
              url="https://www.youtube.com/watch?v=FQ0-41Wre9g"
              config={{
                youtube: { playerVars: { origin: 'https://www.youtube.com' } },
              }}
              width={isMobile ? '18.5rem' : '45.063rem'}
              height={isMobile ? '11.44rem' : '25.313rem'}
              controls
            />
          </SwiperSlide>
          <SwiperSlide key="slide2" tag="li">
            <ReactPlayer
              url="https://www.youtube.com/watch?v=BLShPWrw95Q"
              config={{
                youtube: { playerVars: { origin: 'https://www.youtube.com' } },
              }}
              width={isMobile ? '18.5rem' : '45.063rem'}
              height={isMobile ? '11.44rem' : '25.313rem'}
              controls
            />
          </SwiperSlide>
          <SwiperSlide key="slide1" tag="li">
            <ReactPlayer
              url="https://www.youtube.com/watch?v=qhZZIrSiWFI"
              config={{
                youtube: { playerVars: { origin: 'https://www.youtube.com' } },
              }}
              width={isMobile ? '18.5rem' : '45.063rem'}
              height={isMobile ? '11.44rem' : '25.313rem'}
              controls
            />
          </SwiperSlide>
        </SwiperFrame>
      </ServiceYoutubeContentWrapper>
    </>
  );
};

export default ServiceYoutubeContent;

const ServiceYoutubeContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: #ffffff;
  ${isMobile
    ? css`
        height: 37.125rem;
      `
    : css`
        height: 45.875rem;
      `}
`;

const TitleImg = styled.img`
  ${props =>
    isMobile
      ? props.isen === 'en'
        ? css`
            width: 16.01rem;
            height: 1.61rem;
            margin-top: 6.31rem;
          `
        : css`
            width: 12.06rem;
            height: 2.1rem;
            margin-top: 9.13rem;
          `
      : props.isen === 'en'
      ? css`
          margin-top: 3.55rem;
          width: 11.16rem;
          height: 1.82rem;
        `
      : css`
          margin-top: 5rem;
          width: 15.625rem;
          height: 2.625rem;
        `}
`;

const SwiperFrame = styled(Swiper)`
  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    left: -0.175rem;
    right: auto;
    display: block;
    ${isMobile
      ? css`
          top: 40%;
        `
      : css`
          top: 45%;
        `}
  }
  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    right: 0.625rem;
    left: auto;
    display: block;
    ${isMobile
      ? css`
          top: 40%;
        `
      : css`
          top: 45%;
        `}
  }
  .swiper-button-prev:after {
    content: url('${prevArrowImg}');
  }
  .swiper-button-next:after {
    content: url('${nextArrowImg}');
  }
  .swiper-pagination-bullet {
    background: #205855;
  }
  .swiper-pagination-bullets {
    bottom: 1.25rem;
  }
  iframe {
    border-radius: 1rem;
  }
  ${isMobile
    ? css`
        &.swiper-container {
          position: relative;
          width: 100%;
          height: 14.44rem;
          margin-top: 2.67rem;
          margin-left: auto;
          margin-right: auto;
          list-style: none;
          padding: 0;
          z-index: 1;
          overflow: hidden;
        }
        .swiper-slide-active {
          width: 18.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 11.44rem;
        }
      `
    : css`
        &.swiper-container {
          // position: relative;
          // width: 49.06rem;
          // height: 27.625rem;
          width: 79rem;
          height: 32.625rem;
          margin-top: 2.55rem;
          margin-left: auto;
          margin-right: auto;
          list-style: none;
          padding: 0;
          z-index: 1;
          overflow: hidden;
          justify-content: center;
          align-items: center;
        }
        .swiper-slide-active {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 49.06rem;
          height: 27.625rem;
        }
      `}
`;
