import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useCoreStores } from 'teespace-core';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { isMobile } from 'react-device-detect';
import Cookies from 'js-cookie';
import { Helmet } from 'react-helmet';
import { useCookies } from 'react-cookie';
import { Context } from './ContextPage';
import ServiceHeader from '../components/service/ServiceHeader';
import ServiceTopBanner from '../components/service/ServiceTopBanner';
import ServiceNews from '../components/service/ServiceNews';
import ServiceNewsSwiperContent from '../components/service/ServiceNewsSwiperContent';
import ServiceFeeContent from '../components/service/ServiceFeeContent';
import ServiceIntro from '../components/service/ServiceIntro';
import ServiceYoutubeContent from '../components/service/ServiceYoutubeContent';
import ServiceStoryContent from '../components/service/ServiceStoryContent';
import ServiceTabSwipeContent from '../components/service/ServiceSwipeContent';
import ServiceTabContent from '../components/service/ServiceTabContent';
import ServiceInquiryContent from '../components/service/ServiceInquiryContent';
import ServiceDownloadContent from '../components/service/ServiceDownloadContent';
import ServiceFooter from '../components/service/ServiceFooter';
import arrowImg from '../assets/landing/intro/arrow_top_1.svg';
import ServiceNoticeDialog from '../components/service/ServiceNoticeDialog';
import { useStores } from '../stores';

export default function LandingPage() {
  const history = useHistory();
  const [showFreeTrialBtn, setShowFreeTrialBtn] = useState(false);
  const [showNews, setShowNews] = useState(false);
  const [visibleNotice, setVisibleNotice] = useState(true);
  const { t, i18n } = useTranslation('service');
  const searchParams = new URLSearchParams(window.location.search);
  const { focus } = useParams();
  const [cookies, , removeCookie] = useCookies(['inviteId', 'inviteType']);
  const { themeStore } = useCoreStores();
  const { serviceStore } = useStores();
  const [noticeObj, setNoticeObj] = useState([]);
  const forceRendering = searchParams.get('force');
  const introRef = useRef(null);
  const storyRef = useRef(null);
  const inquiryRef = useRef(null);
  const downloadRef = useRef(null);
  const headerRef = useRef(null);
  const newsRef = useRef(null);
  const newsContextRef = useRef(null);
  const allValue = {
    loadBlogEvent: newsContextRef,
  };

  const refs = {
    headerRef,
    storyRef,
    introRef,
    newsRef,
    inquiryRef,
    downloadRef,
  };

  useEffect(() => {
    themeStore.setTheme('white');
  }, [themeStore]);

  useEffect(() => {
    if (cookies.inviteId && !forceRendering) {
      history.replace(`/${cookies.inviteType}/${cookies.inviteId}`);
    } else {
      removeCookie('inviteId');
      removeCookie('inviteType');
    }
  }, [history]);

  const onHandleNoticeClose = useCallback(() => {
    setVisibleNotice(false);
  }, []);

  const onHandleGoTopClick = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const freeTrialShow = () => {
      if (window.pageYOffset !== 0) {
        setShowFreeTrialBtn(true);
      } else {
        setShowFreeTrialBtn(false);
      }
    };
    window.addEventListener('scroll', freeTrialShow);
    return () => {
      window.removeEventListener('scroll', freeTrialShow);
    };
  }, []);

  useEffect(() => {
    const categoryShow = () => {
      if (
        window.pageYOffset -
          allValue.loadBlogEvent.current?.offsetTop +
          window.innerHeight >
        0
      ) {
        setShowNews(true);
      }
    };
    window.addEventListener('scroll', categoryShow);
    return () => {
      window.removeEventListener('scroll', categoryShow);
    };
  }, []);

  // 공지 fetch
  useEffect(() => {
    const fetchLogic = async () => {
      try {
        const today = new Date();
        await serviceStore.getActiveNotice();
        const activeNoticeList = serviceStore.noticeList
          .filter(notice => notice.location === '0')
          .filter(
            item =>
              // 공지 기간 설정 안 한 경우
              item.endDate === item.startDate ||
              (Date.parse(item.endDate?.slice(0, 21).replace(/ /g, 'T')) >
                today.getTime() &&
                // Safari 파싱 이슈로 replace 구문 추가
                Date.parse(item.startDate?.slice(0, 21).replace(/ /g, 'T')) <
                  today.getTime()),
          )
          .reverse();
        setNoticeObj(activeNoticeList);
      } catch (e) {
        console.log(e);
      }
    };
    fetchLogic();
  }, []);

  return (
    <Context.Provider value={allValue}>
      {i18n.language === 'en' && (
        <Helmet>
          <title>Tmax WAPL - All-in-One Collaboration Tool, Tmax WAPL</title>
        </Helmet>
      )}
      {i18n.language === 'ko' && (
        <Helmet>
          <title>Tmax WAPL - 토핑처럼 골라쓰는 협업툴, 티맥스와플</title>
        </Helmet>
      )}
      <ServiceWrapper>
        <ServiceHeader refs={refs} />
        <ServiceTopBanner />
        <ServiceIntro />
        <ServiceFeeContent isMobile={isMobile} />
        <ServiceYoutubeContent />
        <ServiceStoryContent
          refs={refs}
          isMobile={isMobile}
          startStory={focus === 'story'}
        />
        {!isMobile && (
          <ServiceTabContent refs={refs} startService={focus === 'service'} />
        )}
        {isMobile && (
          <ServiceTabSwipeContent
            refs={refs}
            startService={focus === 'service'}
          />
        )}
        {isMobile && (
          <ServiceNewsSwiperContent
            showNews={showNews}
            refs={refs}
            startNews={focus === 'news'}
          />
        )}
        {!isMobile && (
          <ServiceNews
            showNews={showNews}
            refs={refs}
            startNews={focus === 'news'}
          />
        )}
        <ServiceInquiryContent
          isMobile={isMobile}
          introduction={focus === 'introduction'}
          refs={refs}
        />
        <ServiceDownloadContent
          refs={refs}
          startDownload={focus === 'download'}
        />
        <ServiceFooter isMobile={isMobile} />
        {!isMobile && showFreeTrialBtn && (
          <GoToTop onClick={onHandleGoTopClick}>
            <CustomArrow src={arrowImg} />
            {t('SP_GLOBAL_FOLATING_ICON_02')}
          </GoToTop>
        )}
        {!Cookies?.get('todayNoticeClose') && noticeObj.length !== 0 && (
          <ServiceNoticeDialog
            visible={visibleNotice}
            setVisibleNotice={setVisibleNotice}
            onCancel={onHandleNoticeClose}
            isMobile={isMobile}
            noticeObj={noticeObj}
            refs={refs}
          />
        )}
      </ServiceWrapper>
    </Context.Provider>
  );
}

const GoToTop = styled.div`
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #232d3b;
  box-shadow: 2px 7px 13px 0 rgba(25, 19, 63, 0.2);
  border-radius: 50%;
  position: fixed;
  width: 4.5rem;
  height: 4.5rem;
  right: 17.313rem;
  bottom: 11.25rem;
  font-size: 0.75rem;
  color: #232d3b;
  line-height: 1.125rem;
  text-align: center;
  cursor: pointer;
  z-index: 1;

  &:hover {
    background-color: #f1ece5;
    text-decoration: none;
  }

  &:active {
    background-color: #f1ece5;
    text-decoration: none;
  }
`;

const CustomArrow = styled.img`
  width: 0.7rem;
  height: 0.7rem;
`;

const ServiceWrapper = styled.div`
  ${isMobile
    ? css``
    : css`
        min-width: 1100px;
      `}
`;
