import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import { logEvent, useCoreStores } from 'teespace-core';
import { Button, Menu, Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
// import { useKeycloak } from '@react-keycloak/web';
import { isMobile } from 'react-device-detect';
import iconhamburger from '../../assets/service/hamm.png';
import ServiceHamburger from './ServiceHamburger';
import HeaderLogo from '../../assets/landing/header/logo.png';
import mobileLogo from '../../assets/landing/header/basic.svg';
import arrowBottom from '../../assets/arrow_bottom_1.svg';
import arrowTop from '../../assets/arrow_top_1.svg';
import { keycloakConfig as keycloak } from '../../libs/keycloak';

const ServiceHeader = props => {
  const { refs } = props;
  const history = useHistory();
  const [hamClickState, setHamClickState] = useState(false);
  const { t, i18n } = useTranslation('service');
  const { waplAuthStore, waplUserStore } = useCoreStores();

  // const { keycloak } = useKeycloak();
  const isLocal = process.env.REACT_APP_ENV === 'local';
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const handleDropdownVisibleChange = visible => {
    setDropdownVisible(visible);
  };
  const onHandleClickLogo = () => {
    history.push('/');
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  // const onHandleClickVoucher = () => {
  //   history.push('/voucher');
  //   if (window.location.pathname === '/voucher') {
  //     window.scrollTo({
  //       top:
  //         refs.voucherRef.current.offsetTop -
  //         refs.headerRef.current.clientHeight,
  //       left: 0,
  //       behavior: 'smooth',
  //     });
  //   }
  // };

  const onHandleClickStory = () => {
    history.push('/story');
    window.scrollTo({
      top:
        refs.storyRef.current.offsetTop - refs.headerRef.current.clientHeight,
      left: 0,
      behavior: 'smooth',
    });
  };

  const onHandleClickIntroduce = () => {
    history.push('/service');
    window.scrollTo({
      top:
        refs.introRef.current.offsetTop - refs.headerRef.current.clientHeight,
      left: 0,
      behavior: 'smooth',
    });
  };

  const onHandleClickNews = () => {
    history.push('/news');
    window.scrollTo({
      top: refs.newsRef.current.offsetTop - refs.headerRef.current.clientHeight,
      left: 0,
      behavior: 'smooth',
    });
  };

  const onHandleClickInquiry = () => {
    history.push('/introduction');
    window.scrollTo({
      top:
        refs.inquiryRef.current.offsetTop - refs.headerRef.current.clientHeight,
      left: 0,
      behavior: 'smooth',
    });
  };

  const onHandleClickDownload = () => {
    history.push('/download');
    window.scrollTo({
      top:
        refs.downloadRef.current.offsetTop -
        refs.headerRef.current.clientHeight,
      left: 0,
      behavior: 'smooth',
    });
  };

  const onHandleClickQnA = () => {
    let targetQnA = '';
    if (isLocal) {
      targetQnA = `${window.location.protocol}//${window.location.host}/support`;
    } else {
      targetQnA = `${window.location.protocol}//${window.location.hostname}/support`;
    }
    window.open(targetQnA);
  };

  const onHandleClickRegister = () => {
    window.location.href = `${keycloak.url}/realms/${keycloak.realm}/protocol/openid-connect/registrations?client_id=${keycloak.clientId}&redirect_uri=${window.location.origin}&response_type=code&ui_locales=${i18n.language}`;
  };

  const onHandleHamburgerClick = () => {
    setHamClickState(!hamClickState);
  };

  const onHandleCancelChange = () => {
    setHamClickState(!hamClickState);
  };

  const goMobileStart = () => {
    logEvent('landing', 'clickStartBtn');
    window.location.href = `https://waplai.page.link/main`;
  };

  const handleChangeLanguage = async language => {
    if (waplAuthStore.isAuthenticated) {
      await waplUserStore.updateLanguage({ language });
    }
    i18n.changeLanguage(language);
    window.scrollTo({
      top: 0,
      left: 0,
    });
  };

  const menu = (
    <Menu onClick={() => setDropdownVisible(false)}>
      <Menu.Item key="1" onClick={() => handleChangeLanguage('ko')}>
        {t('common:CM_KOREAN')}
      </Menu.Item>
      <Menu.Item key="2" onClick={() => handleChangeLanguage('en')}>
        {t('common:CM_ENGLISH')}
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <ServiceHeaderWrapper ref={refs.headerRef}>
        {isMobile ? (
          <>
            <MobileLogoImg onClick={onHandleClickLogo} src={HeaderLogo} />

            <MobileHamIcon
              onClick={onHandleHamburgerClick}
              src={iconhamburger}
            />
          </>
        ) : (
          <WLogoImg src={HeaderLogo} onClick={onHandleClickLogo} />
        )}
        {isMobile && (
          <ServiceHamburger
            visible={hamClickState}
            onCancel={onHandleCancelChange}
            refs={refs}
          />
        )}
        <ButtonsWrapper>
          <ServiceUl>
            {/* {i18n.language !== 'en' && (
              <VoucherButtons type="text" onClick={onHandleClickVoucher}>
                {t('SP_VOUCHER_01')}
              </VoucherButtons>
            )} */}
            <HeaderButtons type="text" onClick={onHandleClickStory}>
              {t('SP_SP_INTRO_TAP_01')}
            </HeaderButtons>
            <HeaderButtons type="text" onClick={onHandleClickIntroduce}>
              {t('SP_HOME_01')}
            </HeaderButtons>
            {i18n.language !== 'en' && (
              <HeaderButtons type="text" onClick={onHandleClickNews}>
                {t('SP_NEWS_01')}
              </HeaderButtons>
            )}
            <HeaderButtons type="text" onClick={onHandleClickInquiry}>
              {t('SP_HOME_02')}
            </HeaderButtons>
            <HeaderButtons type="text" onClick={onHandleClickDownload}>
              {t('SP_HOME_03')}
            </HeaderButtons>
            <HeaderButtons type="text" onClick={onHandleClickQnA}>
              {t('SP_HOME_06')}
            </HeaderButtons>
            <BorderDiv />
            <DropdownWrapper>
              <Dropdown
                overlay={menu}
                visible={dropdownVisible}
                onVisibleChange={handleDropdownVisibleChange}
                trigger={['click']}
                getPopupContainer={triggerNode => triggerNode.parentNode}
              >
                <LanguageSelect>
                  Language
                  {dropdownVisible ? <DownIcon /> : <UpIcon />}
                </LanguageSelect>
              </Dropdown>
            </DropdownWrapper>
            <RegisterButton type="text" onClick={onHandleClickRegister}>
              {t('SP_HOME_07')}
            </RegisterButton>
            <StartButton type="solid" onClick={isMobile && goMobileStart}>
              {!isMobile ? (
                <Link to="/spaces">{t('SP_HOME_04')}</Link>
              ) : (
                t('SP_HOME_04')
              )}
            </StartButton>
          </ServiceUl>
        </ButtonsWrapper>
      </ServiceHeaderWrapper>
    </>
  );
};

export default ServiceHeader;

const ServiceHeaderWrapper = styled.div`
  background-color: #ffffff;
  color: #fff;
  display: flex;
  align-items: center;
  position: sticky;
  font-size: 0.938rem;
  top: 0;
  max-width: 100%;
  z-index: 1000;
  ${isMobile
    ? css`
        height: 2.88rem !important;
        justify-content: space-between;
      `
    : css`
        height: 5rem;
        justify-content: center;
      `}
`;

const ButtonsWrapper = styled.div`
  ${isMobile &&
  css`
    display: none;
  `}
`;

const VoucherButtons = styled(Button)`
  border: none !important;
  font-size: 0.938rem;
  font-weight: 500;
  padding: 0rem;
  &:hover {
    border: none;
    color: #ec6222;
  }
  &:active,
  &:focus {
    color: #ed7e49;
    border: none;
  }
`;

const HeaderButtons = styled(Button)`
  padding: 0rem;
  min-width: 1.245rem;
  margin-left: 3.75rem;
  border: none !important;
  font-weight: 500;
  font-size: 0.938rem;
  &:hover {
    color: #ec6222;
    border: none !important;
  }
  &:active,
  &:focus {
    color: #ed7e49;
    border: none !important;
  }
`;

const RegisterButton = styled(Button)`
  min-width: 2.31rem;
  padding: 0rem;
  margin-left: 1.875rem;
  border: none !important;
  font-weight: 400;
  font-size: 0.875rem;
  &:hover {
    color: #ec6222;
    border: none !important;
  }
  &:active,
  &:focus {
    color: #ed7e49;
    border: none !important;
  }
`;

const ServiceUl = styled.ul`
  list-style-type: none;
  margin-left: 6.5rem;
  padding: 0;
  display: flex;
  align-items: center;
`;

const StartButton = styled(Button)`
  margin-left: 1.875rem;
  padding: 0rem;
  min-width: 5.5rem;
  height: 2.5rem;
  font-size: 1rem;
  font-weight: 500;
`;

const WLogoImg = styled.img`
  cursor: pointer;
  // width: 8.875rem;s
  // height: 2.5rem;
`;

const BorderDiv = styled.div`
  height: 0.98rem;
  width: 0.063rem;
  margin-left: 1.875rem;
  margin-right: 1.875rem;
  background-color: #2e3743;
`;

const MobileLogoImg = styled.img`
  width: 4.9rem;
  height: 1.63rem;
  margin-left: 1rem;
`;

const MobileHamIcon = styled.img`
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 1rem;
`;

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  .ant-dropdown {
    z-index: 100000;
  }
`;

const LanguageSelect = styled.span`
  color: #6c635b;
  font-size: 0.81rem;
  line-height: 0.78rem;
  cursor: pointer;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  margin-left: auto;
  right: 0;

  &.ant-dropdown-trigger span.anticon {
    font-size: 0.55rem;
    margin-top: 0.125rem;
    margin-left: 0.40625rem;
  }
`;

const DownIcon = styled.i`
  display: inline-block;
  mask-image: url('${arrowBottom}');
  mask-repeat: no-repeat;
  mask-size 100%;
  background-color:#959089;
  width: 0.875rem;
  height: 0.875rem;
  margin-left: 0.25rem;
`;

const UpIcon = styled.i`
  display: inline-block;
  mask-image: url('${arrowTop}');
  mask-repeat: no-repeat;
  mask-size 100%;
  background-color:#959089;
  width: 0.875rem;
  height: 0.875rem;
  margin-left: 0.25rem;
`;
