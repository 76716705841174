import React from 'react';
import styled, { css } from 'styled-components';
import WaplLogoImg from '../assets/wapl_support_logo_full.png';
import WaplLogoDarkImg from '../assets/combination_dark.svg';

export default props => {
  return <WaplLogo {...props} />;
};

const handleLogoImg = themeName => {
  switch (themeName) {
    case 'white':
      return WaplLogoImg;
    case 'dark':
      return WaplLogoDarkImg;
    default:
      return '';
  }
};
const WaplLogo = styled.div`
  background-image: url('${({ theme }) => handleLogoImg(theme.name)}');
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
`;
