import React, { useState, useCallback } from 'react';
import { useCoreStores } from 'teespace-core';
import styled, { css } from 'styled-components';
import { isMobile } from 'react-device-detect';
import { Menu, Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import endingText from '../../assets/landing/footer/main.png';
import arrowBottom from '../../assets/arrow_bottom_1.svg';
import arrowTop from '../../assets/arrow_top_1.svg';
import back4 from '../../assets/landing/footer/BG.png';
import mobileTxt from '../../assets/landing/footer/mobile_title.png';
import mobileBg from '../../assets/landing/footer/mobile_bg.png';
import mobileScroll from '../../assets/landing/footer/mobile_scroll.png';
import facebookImage from '../../assets/landing/footer/facebook.png';
import youtubeImage from '../../assets/landing/footer/youtube.png';
import linkedinImage from '../../assets/landing/footer/linkedin.png';
import naverblogImage from '../../assets/landing/footer/naverblog.png';
import mainEn from '../../assets/landingEn/footer/main_En.png';
import textEn from '../../assets/landingEn/mobile/footer/main.png';
import mobileBasicLogo from '../../assets/landing/header/basic.svg';

const ServiceFooter = () => {
  const { t, i18n } = useTranslation(['common', 'service']);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { waplAuthStore, waplUserStore } = useCoreStores();

  const onHandleFacebookClick = useCallback(() => {
    window.open(`https://www.facebook.com/TmaxHQ`);
  }, []);

  const onHandleYoutubeClick = useCallback(() => {
    window.open(
      `https://www.youtube.com/channel/UCGxa230hX8qiBsaHLoQU4Bw/featured`,
    );
  }, []);

  const onHandleLinkedInClick = useCallback(() => {
    window.open(`https://www.linkedin.com/company/wapl-ai`);
  }, []);

  const onHandleNaverblogClick = useCallback(() => {
    window.open(`https://blog.naver.com/wapl_ai`);
  }, []);

  const handleDropdownVisibleChange = visible => {
    setDropdownVisible(visible);
  };

  const handleChangeLanguage = async language => {
    if (waplAuthStore.isAuthenticated) {
      await waplUserStore.updateLanguage({ language });
    }
    i18n.changeLanguage(language);
    window.scrollTo({
      top: 0,
      left: 0,
    });
  };
  const menu = (
    <Menu onClick={() => setDropdownVisible(false)}>
      <Menu.Item key="1" onClick={() => handleChangeLanguage('ko')}>
        {t('common:CM_KOREAN')}
      </Menu.Item>
      <Menu.Item key="2" onClick={() => handleChangeLanguage('en')}>
        {t('common:CM_ENGLISH')}
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      {!isMobile && (
        <ServiceTextFooterWrapper>
          <ServiceFooterTitleImage
            id="mobile-footer-img1"
            alt="text"
            src={i18n.language === 'en' ? mainEn : endingText}
            data-aos-offset="40"
            data-aos="fade-up"
            data-aos-duration="750"
            data-aos-easing="ease"
          />
        </ServiceTextFooterWrapper>
      )}
      {isMobile && (
        <MobileServiceWrapper>
          <MobileTextFooter src={i18n.language === 'en' ? textEn : mobileTxt} />
          <MobileLogo />
          <MobileGoToTop onClick={() => window.scrollTo({ top: 0 })} />
        </MobileServiceWrapper>
      )}
      <ServiceLogoFooterWrapper>
        <FooterHeaderWrapper>
          <MobileLogoWrapper>
            <TmaxWaplLogo
              alt="logo"
              src={`${process.env.PUBLIC_URL}/logo_footer_copyright.png`}
              ismobile={isMobile}
            />
            {isMobile && <span>{`${t('service:SP_BTM_MENU_03')}`}</span>}
          </MobileLogoWrapper>
          <DropdownWrapper>
            <Dropdown
              overlay={menu}
              visible={dropdownVisible}
              onVisibleChange={handleDropdownVisibleChange}
              trigger={['click']}
              getPopupContainer={triggerNode => triggerNode.parentNode}
            >
              <LanguageSelect>
                Language
                {dropdownVisible ? <DownIcon /> : <UpIcon />}
              </LanguageSelect>
            </Dropdown>
          </DropdownWrapper>
        </FooterHeaderWrapper>
        <FooterDescription ismobile={isMobile}>
          {isMobile ? (
            <span>{`${t('service:SP_BTM_MENU_04')}`}</span>
          ) : (
            <span>{t('service:SP_BTM_MENU_01')}</span>
          )}
          <br />
          <PrivacyLink href="/privacy-policy" target="_new">
            {t('service:SP_DOWNLOAD_06')}
          </PrivacyLink>
          ㅣ
          <FooterLink href="term-and-conditions" target="_new">
            {t('service:SP_DOWNLOAD_05')}
          </FooterLink>
          {isMobile && (
            <>
              <span>
                ㅣ {`${t('service:SP_BTM_MENU_05')}`}ㅣ<br />
                {`${t('service:SP_BTM_MENU_06')}`}ㅣ<br />
                {`${t('service:SP_BTM_MENU_07')}`}
                <a href="mailto:wapl_support@tmax.co.kr">
                  {t(`service:SP_BTM_MENU_08`)}
                </a>
                | {`${t('service:SP_BTM_MENU_09')}`}
                <a href="tel:1800-5566">{t(`service:SP_BTM_MENU_10`)}</a>
              </span>
              <br /> ©copyright 2021. TmaxWAPL Corp. All rights reserved
            </>
          )}
          {!isMobile && (
            <span>
              {` ${t('service:SP_BTM_MENU_02')}`}
              <br /> ©copyright 2021. TmaxWAPL Corp. All rights reserved
            </span>
          )}
        </FooterDescription>
        <SnsWrapper ismobile={isMobile}>
          <SnsItem>
            {' '}
            <NaverblogImage
              src={naverblogImage}
              onClick={onHandleNaverblogClick}
            />
            <SnsText onClick={onHandleNaverblogClick}>Blog</SnsText>
          </SnsItem>
          <LineBar />
          <SnsItem>
            <YoutubeImage src={youtubeImage} onClick={onHandleYoutubeClick} />
            <SnsText onClick={onHandleYoutubeClick}>Youtube</SnsText>
          </SnsItem>
          <LineBar />
          <SnsItem>
            <LinkedInImage
              src={linkedinImage}
              onClick={onHandleLinkedInClick}
            />
            <SnsText onClick={onHandleLinkedInClick}>Linkedin</SnsText>
          </SnsItem>
          <LineBar />
          <SnsItem>
            <FacebookImage
              src={facebookImage}
              onClick={onHandleFacebookClick}
            />
            <SnsText onClick={onHandleFacebookClick}>Facebook</SnsText>
          </SnsItem>
        </SnsWrapper>
      </ServiceLogoFooterWrapper>
    </>
  );
};

export default ServiceFooter;

const DownIcon = styled.i`
  display: inline-block;
  mask-image: url('${arrowBottom}');
  mask-repeat: no-repeat;
  mask-size 100%;
  background-color:#959089;
  width: 0.875rem;
  height: 0.875rem;
  margin-left: 0.25rem;
`;

const UpIcon = styled.i`
  display: inline-block;
  mask-image: url('${arrowTop}');
  mask-repeat: no-repeat;
  mask-size 100%;
  background-color:#959089;
  width: 0.875rem;
  height: 0.875rem;
  margin-left: 0.25rem;
`;

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  .ant-dropdown {
    z-index: 100000;
  }
`;
const LanguageSelect = styled.span`
  color: #6c635b;
  font-size: 0.81rem;
  line-height: 0.78rem;
  cursor: pointer;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  margin-left: auto;
  right: 0;

  &.ant-dropdown-trigger span.anticon {
    font-size: 0.55rem;
    margin-top: 0.125rem;
    margin-left: 0.40625rem;
  }
`;
const ServiceTextFooterWrapper = styled.div`
  height: 20rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2ede6;
  background-image: url(${back4});
  background-repeat: no-repeat;
  background-size: cover;
`;

const ServiceLogoFooterWrapper = styled.div`
  ${isMobile
    ? css`
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 1.31rem;
        background: #ebe6df;
      `
    : css`
        padding-top: 1.875rem;
        padding-bottom: 2.5rem;
        padding-left: 20rem;
        padding-right: 20rem;
        background: #ffffff;
      `}
`;

const TmaxWaplLogo = styled.img`
  // width: 8.138rem;
  // height: 1rem;
`;

const FooterDescription = styled.p`
  ${props =>
    props.ismobile
      ? css`
          margin-top: 0.5rem;
          margin-bottom: 0.56rem;
          font-size: 0.69rem;
          line-height: 1.06rem;
          font-weight: 500;
        `
      : css`
          margin-top: 0.625rem;
          margin-bottom: 1.313rem;
          font-size: 0.813rem;
        `}
  color: #6c635b;
  a {
    color: #6c635b;
    & hover {
      color: #6c635b;
    }
  }
`;

const FooterLink = styled.a`
  color: #6c635b;
  // font-size: 0.578rem;
`;

const PrivacyLink = styled(FooterLink)`
  font-weight: bold;
`;

const SnsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.125rem;
`;

const SnsText = styled.div`
  line-height: 0.846rem;
  cursor: pointer;
  padding-left: 0.27rem;
  font-size: 0.813rem;
  color: #666666;
`;

const NaverblogImage = styled.img`
  width: 1.063rem;
  height: 1.063rem;
  cursor: pointer;
`;

const YoutubeImage = styled.img`
  width: 1.063rem;
  height: 0.75rem;
  cursor: pointer;
`;

const LinkedInImage = styled.img`
  width: 1rem;
  height: 1rem;
  cursor: pointer;
`;

const FacebookImage = styled.img`
  width: 1rem;
  height: 1rem;
  cursor: pointer;
`;

const LineBar = styled.span`
  width: 0.89rem;
`;

const MobileServiceWrapper = styled.div`
  background-image: url('${mobileBg}');
  background-repeat: no-repeat;
  background-size: 100% auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const MobileTextFooter = styled.img`
  margin-top: 9.81rem;
`;

const MobileLogo = styled.div`
  background-image: url('${mobileBasicLogo}');
  background-repeat: no-repeat;
  background-size: contain;
  width: 10rem;
  height: 2.88rem;
  margin-top: 1.13rem;
  margin-bottom: 6.81rem;
`;

const MobileLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  span {
    margin-left: 0.38rem;
    font-size: 0.69rem;
    line-height: 0.69rem;
    color: #6c635b;
    font-weight: 500;
  }
`;

const FooterHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
`;

const SnsItem = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
`;

const MobileGoToTop = styled.div`
  cursor: pointer;
  background-image: url('${mobileScroll}');
  background-repeat: no-repeat;
  background-size: contain;
  width: 2.13rem;
  height: 2.13rem;
  margin-bottom: 0.81rem;
`;

const ServiceFooterTitleImage = styled.img`
  width: 34.625rem;
  height: 7.813rem;
`;
