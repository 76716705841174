import React, { useState, useEffect, useRef, useContext } from 'react';
import { Button } from 'teespace-core';
import 'swiper/swiper-bundle.css';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import styled, { css } from 'styled-components';
import { Context } from '../../pages/ContextPage';
import slide1 from '../../assets/landing/intro/screen_01.png';
import slide2 from '../../assets/landing/intro/screen_02.png';
import slide3 from '../../assets/landing/intro/screen_03.png';
import slide4 from '../../assets/landing/intro/screen_04.png';
import slide5 from '../../assets/landing/intro/screen_05.png';
import slide1_En from '../../assets/landingEn/main/screen_01_En.png';
import slide2_En from '../../assets/landingEn/main/screen_02_En.png';
import slide3_En from '../../assets/landingEn/main/screen_03_En.png';
import slide4_En from '../../assets/landingEn/main/screen_04_En.png';
import slide5_En from '../../assets/landingEn/main/screen_05_En.png';
import maintext from '../../assets/landing/intro/main.png';
import graphic1 from '../../assets/landing/intro/mobile_graphic01.png';
import graphic2 from '../../assets/landing/intro/mobile_graphic02.png';
import graphic3 from '../../assets/landing/intro/mobile_graphic03.png';
import mobileMaintext from '../../assets/landing/intro/mobile_main.png';
import back1 from '../../assets/service/r01.main_background.png';
import webGraphic2 from '../../assets/landing/main/graphics_green.png';
import webGraphic3 from '../../assets/landing/main/graphics_orange.png';
import fastTrackImg from '../../assets/landing/intro/fasttrak.png';
import introductionImg from '../../assets/landing/intro/sub_2.png';
import main_En from '../../assets/landingEn/main/main_En.png';
import sub_En from '../../assets/landingEn/main/sub_En.png';
import sub2_En from '../../assets/landingEn/main/sub2_EN.png';
import mobileFreetext from '../../assets/landing/mobile/main/free.png';
import mobileFreetext_En from '../../assets/landingEn/mobile/intro/sub.png';

SwiperCore.use([Pagination, Autoplay]);

const ServiceIntro = () => {
  const freeStartBtnRef = useContext(Context);
  const [slidesPerViewCount, setSlidesPerViewCount] = useState(5);
  const { t, i18n } = useTranslation('service');

  useEffect(() => {
    isMobile ? setSlidesPerViewCount(1) : setSlidesPerViewCount(5);
  }, [slidesPerViewCount]);

  const goMobileStart = () => {
    window.location.href = `https://waplai.page.link/main`;
  };

  return (
    <>
      <ServiceIntroWrapper id="mobileintrowrapper">
        <Colmain>
          <ColLeft>
            <TabletWrapper>
              <FastTrackImage
                src={i18n.language === 'en' ? sub_En : fastTrackImg}
                data-aos="fade-up"
                data-aos-duration="750"
                data-aos-offset="40"
                data-aos-easing="ease"
              />
              <IntroLeftImg
                isen={i18n.language}
                src={
                  i18n.language === 'en'
                    ? main_En
                    : isMobile
                    ? mobileMaintext
                    : maintext
                }
              />
              {!isMobile && (
                <a
                  href={
                    i18n.language === 'en'
                      ? '/waplGuide_En.pdf'
                      : '/waplGuide.pdf'
                  }
                  download={
                    i18n.language === 'en'
                      ? '(WAPL)Service Introduction_220401.pdf'
                      : '(WAPL)제품소개서_220401.pdf'
                  }
                >
                  <ProductIntroduction
                    src={i18n.language === 'en' ? sub2_En : introductionImg}
                    data-aos="fade-up"
                    data-aos-duration="750"
                    data-aos-offset="40"
                    data-aos-easing="ease"
                  />
                </a>
              )}
            </TabletWrapper>

            {isMobile && (
              <>
                <a
                  href={
                    i18n.language === 'en'
                      ? '/waplGuide_En.pdf'
                      : '/waplGuide.pdf'
                  }
                  download={
                    i18n.language === 'en'
                      ? '(WAPL)Service Introduction_220401.pdf'
                      : '(WAPL)제품소개서_220401.pdf'
                  }
                >
                  <MobileProduction
                    src={i18n.language === 'en' ? sub2_En : introductionImg}
                  />
                </a>

                <MobileTextDiv>
                  <MobileFreeText
                    src={
                      i18n.language === 'en'
                        ? mobileFreetext_En
                        : mobileFreetext
                    }
                  />
                </MobileTextDiv>
              </>
            )}
            <IntroButtonWrapper ref={freeStartBtnRef.showBannerValue}>
              <IntroLeftButton
                id="mobileMainButton"
                type="solid"
                onClick={isMobile && goMobileStart}
              >
                {!isMobile ? (
                  <Link to="/spaces">{t('SP_HOME_05')}</Link>
                ) : (
                  t('SP_HOME_05')
                )}
              </IntroLeftButton>
            </IntroButtonWrapper>
          </ColLeft>
          <ColRight>
            <SwiperWrapper>
              <Swiper
                id="main"
                pagination={{ clickable: true }}
                loop
                autoplay={{ delay: 3000 }}
                slidesPerView={1}
              >
                <SwiperSlide key="slide0" tag="li">
                  <MainMockupImg
                    src={i18n.language === 'en' ? slide1_En : slide1}
                    alt="Slide0"
                  />
                </SwiperSlide>
                <SwiperSlide key="slide1" tag="li">
                  <MainMockupImg
                    src={i18n.language === 'en' ? slide2_En : slide2}
                    alt="Slide1"
                  />
                </SwiperSlide>
                <SwiperSlide key="slide2" tag="li">
                  <MainMockupImg
                    src={i18n.language === 'en' ? slide3_En : slide3}
                    alt="Slide2"
                  />
                </SwiperSlide>
                <SwiperSlide key="slide3" tag="li">
                  <MainMockupImg
                    src={i18n.language === 'en' ? slide4_En : slide4}
                    alt="Slide3"
                  />
                </SwiperSlide>
                <SwiperSlide key="slide4" tag="li">
                  <MainMockupImg
                    src={i18n.language === 'en' ? slide5_En : slide5}
                    alt="Slide4"
                  />
                </SwiperSlide>
              </Swiper>
            </SwiperWrapper>
          </ColRight>

          {!isMobile && (
            <>
              <WebGraphics2 src={webGraphic2} alt="green" />
              <WebGraphics3 src={webGraphic3} alt="orange" />
            </>
          )}
        </Colmain>
      </ServiceIntroWrapper>
    </>
  );
};
export default ServiceIntro;

const ServiceIntroWrapper = styled.div`
  display: flex;
  flex-direction: row;
  ${isMobile
    ? css`
        background: #f0ebe4;
        height: 100%;
        background-image: url('${graphic1}'), url('${graphic2}'),
          url('${graphic3}');
        background-repeat: no-repeat;
        background-size: auto, auto, auto;
        background-position: right -90px top 70px, right -64px bottom 120px,
          left -44px bottom 90px;
      `
    : css`
        background-color: #ffffff;
        height: 52.375rem;
        justify-content: center;
        align-items: center;
        .swiper-pagination-bullet {
          background: #205855;
        }
      `}
`;

const Colmain = styled.div`
  display: flex;
  flex-direction: row;
  width: 80rem;
  height: 100%;
  margin-top: 4rem;
`;

const ColLeft = styled.div`
  ${isMobile
    ? css`
        width: 100%;
      `
    : css`
        height: 100%;
        padding-top: 6.57rem;
      `}
`;

const ColRight = styled.div`
  ${isMobile
    ? css`
        display: none;
      `
    : css`
        flex: 1;
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 41rem;
        width: 60.625rem;
        padding-top: 1.7rem;
        padding-left: 9.313rem;
      `}
`;

const SwiperWrapper = styled.div`
  .swiper-container {
    height: 41rem;
    width: 65.313rem;
  }
  .swiper-pagination {
    bottom: 0rem;
    left: 0;
    width: 100%;
  }
`;

const MainMockupImg = styled.img`
  height: 38rem;
  width: 60rem;
  margin-top: 2rem;
  margin-left: 3rem;
  list-style: none;
`;

const IntroLeftImg = styled.img`
  ${props =>
    isMobile
      ? props.isen === 'en'
        ? css`
            position: relative;
            margin-top: 0.75rem;
            margin-left: 1.88rem;
            margin-bottom: 0.5rem;
            width: 19.5rem;
            height: 17.25rem;
          `
        : css`
            position: relative;
            margin-top: 0.75rem;
            margin-left: 1.88rem;
            margin-bottom: 0.5rem;
            width: 15.18rem;
            height: 15.45rem;
          `
      : css`
          width: 19.375rem;
          height: 17.938rem;
        `}
`;

const IntroLeftButton = styled(Button)`
  ${isMobile
    ? css`
        margin-top: 0 !important;
        width: calc(100% - 2rem);
        margin-left: 1rem;
        margin-right: 1rem;
        height: 2.5rem;
      `
    : css`
        margin-top: 1.875rem;
        width: 19.375rem;
        height: 3.063rem;
        font-size: 1.25rem;
      `}
`;

const IntroButtonWrapper = styled.div`
  ${isMobile &&
  css`
    text-align: center;
    margin-bottom: 1.88rem;

    & button {
      font-size: 1rem;
    }
  `}
`;

const WebGraphics2 = styled.img`
  width: 14.313rem;
  height: 16.75rem;
  position: relative;
  left: -12.5rem;
  top: 23.5rem;
`;

const WebGraphics3 = styled.img`
  width: 14.625rem;
  height: 22.313rem;
  position: relative;
  left: -69.5rem;
  top: 5rem;
`;

const FastTrackImage = styled.img`
  ${props =>
    props.ismobile
      ? css`
          width: 7.19rem;
          height: 3.44rem;
          margin-top: 2.75rem;
          margin-left: 1.69rem;
        `
      : css`
          width: 11.5rem;
          height: 5.313rem;
          margin-bottom: 0.875rem;
        `}
`;

const ProductIntroduction = styled.img`
  ${props =>
    props.ismobile
      ? css`
          display: none;
        `
      : css`
          width: 10rem;
          height: 1.813rem;
          margin-top: 0.688rem;
          cursor: pointer;
        `}
`;

const MobileTextDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const MobileProduction = styled.img`
  position: relative;
  margin-left: 1.88rem;
`;

const MobileFreeText = styled.img`
  position: relative;
  margin-top: 2.5rem;
  margin-bottom: 0.88rem;
`;

const TabletWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
